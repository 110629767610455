import React from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

function Mainlogo(props) {
  return (
    <div id="Mainlogo" className={`${props.showBanner ? 'showbanner' : ''}${props.mainmenuIsOpen ? ' open' : ''}${props.animateBanner ? ' animate' : ''}`}>
    	<Link to="/" onClick={() => props.dispatch({type: 'CLOSE_MAINMENU'})}>PSV Academy</Link>
    </div>
  );
}

export default connect(state => ({
	mainmenuIsOpen: state.Globals.mainmenuIsOpen,
	showBanner: state.App.showBanner,
	animateBanner: state.App.animateBanner
}))(Mainlogo);