import React, {useEffect, useState} from 'react';
import './index.scss';

import {getApiBase} from './../../helpers'
import {useParams, useLocation} from 'react-router-dom'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'

import HeaderTextModule from './../PageModules/HeaderTextModule'
import CTASplitterModule from './../PageModules/CTASplitterModule'
import CTASplitterSmallModule from './../PageModules/CTASplitterSmallModule'
import CasesAllModule from './../PageModules/CasesAllModule'
import CasesSelectedModule from './../PageModules/CasesSelectedModule'
import PeopleAllModule from './../PageModules/PeopleAllModule'
import PeopleSelectedModule from './../PageModules/PeopleSelectedModule'
import TestimonialModule from './../PageModules/TestimonialModule'
import TextAndImageModule from './../PageModules/TextAndImageModule'
import NewsModule from './../PageModules/NewsModule'
import FaqModule from './../PageModules/FaqModule'
import WeKnowModule from './../PageModules/WeKnowModule'
import CodexModule from './../PageModules/CodexModule'
import PressModule from './../PageModules/PressModule'
import UntitledModule from './../PageModules/UntitledModule'
import ThoughtsAllModule from './../PageModules/ThoughtsAllModule'
import UploadPitchModule from './../PageModules/UploadPitchModule'
import ContactBigCTAButtonsModule from './../PageModules/ContactBigCTAButtonsModule'
import BreakerWithMultipleImages from './../PageModules/BreakerWithMultipleImages'
import PeopleStrategyAnimationModule from './../PageModules/PeopleStrategyAnimationModule'
import WinbatsBreaker from './../PageModules/WinbatsBreaker'
import HubSpotForm from './../PageModules/HubSpotForm'
import Footer from './../Footer'

// PSV Academy specific
import EventArchiveAll from './../PageModules/EventArchiveAll'
import ArchiveModule from './../PageModules/ArchiveModule'
import Tagcloud from './../PageModules/Tagcloud'
import EventsUpcomingTeaser from './../PageModules/EventsUpcomingTeaser'

function NormalPage(props) {
  useEffect(() => {
    if (props.routerAction === 'POP' && props.lastScroll) window.scrollTo(0, props.lastScroll.val)
    else window.scrollTo(0,0);
  }, [props.dataKey])

  return (
  	<div className={`page${props.mainmenuIsOpen ? ' open' : ''}${props.showBanner ? ' showbanner' : ''}${props.animateBanner ? ' animate' : ''}`}>
      {props.pathname !== '/' && props.pathname == props.dataKey ? 
        <Helmet>
          <title>PSV Academy ∙ {props.apiData.title}</title>
          <meta name="description" content={props.apiData.meta.description ? props.apiData.meta.description : props.meta.description_global}/>
        </Helmet>
        : 
        <Helmet>
          <title>PSV Academy</title>
          <meta name="description" content={props.apiData.meta.description ? props.apiData.meta.description : props.meta.description_global}/>
        </Helmet>
      }
      	{props.apiData.pagemodules ? props.apiData.pagemodules.map((ele, i) => (
            ele.acf_fc_layout === 'press' ? <PressModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'untitled_section' ? <UntitledModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'codex' ? <CodexModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'we_know' ? <WeKnowModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'cases_all' ? <CasesAllModule key={i} data={ele} archivePosts={props.apiData.archivePosts} slug={props.dataKey} num={i}/> :
            ele.acf_fc_layout === 'cases_selected' ? <CasesSelectedModule key={i} data={props.apiData.selectedCases} viewAll={ele.view_all ? ele.view_all[0] : null} viewAllFilter={ele.view_all_filter} num={i}/> :
            ele.acf_fc_layout === 'thoughts_all' ? <ThoughtsAllModule key={i} data={ele} archivePosts={props.apiData.archivePosts} slug={props.dataKey} num={i}/> :
            ele.acf_fc_layout === 'people_all' ? <PeopleAllModule key={i} data={ele} archivePosts={props.apiData.archivePosts} slug={props.dataKey} num={i}/> :
            ele.acf_fc_layout === 'people_selected' ? <PeopleSelectedModule key={i} data={ele} selectedPeople={props.apiData.selectedPeople} num={i}/> :
            ele.acf_fc_layout === 'testimonial' ? <TestimonialModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'header_text' ? <HeaderTextModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'cta_splitter' ? <CTASplitterModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'cta_splitter_small' ? <CTASplitterSmallModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'text_and_image' ? <TextAndImageModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'faq' ? <FaqModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'news' ? <NewsModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'upload_pitch_form' ? <UploadPitchModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'contact_big_cta_buttons' ? <ContactBigCTAButtonsModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'breaker_with_multiple_images' ? <BreakerWithMultipleImages key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'breaker_with_multiple_images' ? <BreakerWithMultipleImages key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'people_strategy_animation' ? <PeopleStrategyAnimationModule key={i} data={ele} num={i}/> :
            ele.acf_fc_layout === 'hubspot_form' ? <HubSpotForm key={i} data={ele} num={i}/> :
            
            // PSV ACADEMY specific
            ele.acf_fc_layout === 'events_past' ? <EventArchiveAll key={i} slug={props.dataKey} data={ele} num={i} /> :
            ele.acf_fc_layout === 'events_upcoming' ? <ArchiveModule key={i} data={ele} slug={props.dataKey} num={i} firstModule="events"/> :
            ele.acf_fc_layout === 'upcoming_events_teaser' ? <EventsUpcomingTeaser key={i} data={ele} slug={props.dataKey} num={i}/> :
            ele.acf_fc_layout === 'resources_all' ? <ArchiveModule key={i} data={ele} slug={props.dataKey} num={i} firstModule="resources"/> :
            ele.acf_fc_layout === 'speakers_all' ? <ArchiveModule key={i} data={ele} slug={props.dataKey} num={i} firstModule="speakers"/> :
            ele.acf_fc_layout === 'winbats_breaker' ? <WinbatsBreaker key={i} num={i} slug={props.dataKey}/> :
            ele.acf_fc_layout === 'tagcloud' ? <Tagcloud key={i} num={i} data={ele}/> :

            null
        )) : null}
        <Footer/>

    </div>
  );
}

export default connect((state, ownProps) => {
  let browserKeyForLastScroll = state.router.location.key ? state.router.location.key : 'init'
  return ({
	apiData: state.App.pageData[ownProps.dataKey].data,
  pathname: state.router.location.pathname,
  mainmenuIsOpen: state.Globals.mainmenuIsOpen,
  lastScroll: state.App.scrollhistory[browserKeyForLastScroll],
  routerAction: state.router.action,
  meta: state.Globals.meta,
  showBanner: state.App.showBanner,
  animateBanner: state.App.animateBanner
})
})(NormalPage);
