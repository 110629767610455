import React, {useState} from 'react';
import './index.scss';

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LazyImageComponent from './../../LazyImageComponent'

function CodexModule(props) {
    const [showEmail, setShowEmail] = useState(false)

    return (
        <div className="PageModule PressModule">
        	<div className="col col8 col16-l">
        		<div className="inner">
        			<div className="title">{props.data.left_column_title}</div>
        			<div className="portrait">
        				<LazyImageComponent height={props.data.image.height} width={props.data.image.width} url={props.data.image.sizes.large}/>
        			</div>
        			<div className="data">
        				<div className="info">{props.data.description}</div>
                        <div className="name">{props.data.contactperson_name}</div>
                        <div className="cred entry">{props.data.contactperson_title}</div>
                        <div className="phone entry"><a href={`tel:${props.data.contactperson_phone}`}>{props.data.contactperson_phone}</a></div>
        				<div className="email entry"><a href={`mailto:${showEmail ? props.data.contactperson_email : ''}`} onMouseEnter={() => setShowEmail(true)}>{showEmail ? props.data.contactperson_email : 'write me an email'}</a></div>
        			</div>
        		</div>
        	</div>
        	<div className="col col8 col16-l">
        		<div className="inner">
        			<div className="title">{props.data.right_column_title}</div>
        			<ul className="files">
        				{props.data.files_to_download.length > 0 ? props.data.files_to_download.map((ele, i) => (
        					<li key={i}>
            					<div className="filedata">
            						<div className="name">{ele.title}</div>
            						<div className="info">{ele.info}</div>
            					</div>
            					<a href={ele.file} target="_blank" className="btn black">Download</a>
            				</li>
        				)) : null}
        			</ul>
        		</div>
        	</div>
        </div>
    );
}

export default CodexModule;
