import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link} from 'react-router-dom'

import LazyImageComponent from './../../LazyImageComponent'

import {ReactComponent as ArrowIcon} from './../../../../assets/icons/arrowswungicon.svg'
import {ReactComponent as IconTime} from './../../../../assets/icons/icon-time.svg'
import {ReactComponent as IconLocation} from './../../../../assets/icons/icon-location.svg'
import {ReactComponent as IconCal} from './../../../../assets/icons/icon-cal.svg'
import {ReactComponent as IconPrice} from './../../../../assets/icons/icon-price.svg'

function EventArchiveSingle(props) {
  return (
 	<div className={`col col16 EventArchiveSingle ${props.data.previous_event.is_previous_event ? 'previous' : 'upcoming'}`}>
 		<div className="inner">
	 		<Link to={props.data.previous_event.is_previous_event ? '/resources/'+props.data.previous_event.resource_for_previous_event[0].post_name : '/events/'+props.data.post_name}>
	 			<div className="thumb">
	    			<LazyImageComponent height={props.data.image_thumb.height} width={props.data.image_thumb.width} url={props.data.image_thumb.url}/>
	    		</div>
			 	<div className="content">
			 		<h3 className="title">{props.data.post_title}</h3>
			 		<div className="data">
			 			<div className="date entry"><IconCal/>{props.data.start.date}{props.data.start.date !== props.data.end.date ? ' - '+props.data.end.date : ''}</div>
		 				{props.data.start.date == props.data.end.date ? <div className="time entry"><IconTime/>{props.data.start.time} - {props.data.end.time}</div> : null}
		 				{props.data.location ? <div className="location entry"><IconLocation/>{props.data.location}</div> : null}
		 				{props.data.price ? <div className="price entry"><IconPrice/>{props.data.price}</div> : null}
		 			</div>
		 		</div>

		 		{props.data.previous_event.is_previous_event ? (
		 			<div>
			 			<div className="overlay"/> 
			 			<div className="btn black view_resources">View resources from this event</div>
		 			</div>
		 			): 
		 			<ArrowIcon/>
		 		}
	 		</Link>
		</div>
	</div>
  );
}

export default connect()(EventArchiveSingle);
