import React from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link} from 'react-router-dom'

import ResourceArchiveSingle from './../ResourceArchiveSingle'

function ResourceArchiveAll(props) {
  return (
 	props.archivePosts ? 
	    <div className="PageModule ResourceArchiveAll">
	    	<div className="col col16 indicator">
	    		<div className="inner">Resources</div>
	    	</div>
	    	{props.archivePosts.posts.length > 0 ?
				<div className="thumblist">
	    	 		{props.archivePosts.posts.map((ele, i) => 
	    	 			<ResourceArchiveSingle key={i} data={ele}/>
    	 			)}
				</div>
			: 

				<div className="col col16">
	                {props.query.cat ? <div className="inner not-found">No resources for "{props.archiveCategories.all.find(ele => ele.slug == props.query.cat).name}"</div> : null}
	              </div>
			}
	    	 	{
	    	 		props.archivePosts ? !props.archivePosts.allPostsLoaded ? 
	    	 			<Link className="btn black loadmore_btn" to={{search: `?page=${props.currentRouterPage ? parseInt(props.currentRouterPage)+1 : '2'}${props.currentRouterCat ? '&cat='+props.currentRouterCat : ''}`, state: {fromTag: true}}}>Load more</Link>
		 			: null : null
		 		}
	    </div>
    : null
  );
}

export default connect((state, ownProps) => ({
  	archiveCategories: state.App.pageData[ownProps.slug].data.archiveCategories,
  	query: state.router.location.query,
  	archivePosts: state.App.pageData[ownProps.slug].data.archiveResources,
  	currentRouterPage: state.router.location.query ? state.router.location.query.page ? state.router.location.query.page : null : null,
  	currentRouterCat: state.router.location.query ? state.router.location.query.cat ? state.router.location.query.cat : null : null
}))(ResourceArchiveAll);
