import React from 'react';
import './index.scss';

import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'

import {ReactComponent as MenuIcon} from './../../../assets/icons/plusicon.svg'

function TopMenu(props) {
  return (
  		<div className={`${props.showBanner ? 'showbanner' : ''} Topmenu`}>
	    	{props.menu ? 
				props.menu.mainmenu.map((ele, i) => (
					ele.menuitem_type === 'pagelink' ? 
		  	 			<div className="menuitem" key={i}><NavLink key={i} to={{pathname: "/" + ele.menuitem_page[0].post_name, state: {fromTag: true}}}>{ele.menuitem_page[0].post_title}</NavLink></div>
		  	 		: 
	  	 				<div className="menuitem" key={i}>
	  	 					{ele.submenu_name}
			  	 			<div className="submenu">
			  	 					{ele.submenu_items.map((ele2, i2) => (
			  	 							<NavLink key={i2} className="submenuitem" to={{pathname:"/" + ele2.post_name, state: {fromTag: true}}}>{ele2.post_title}</NavLink>
			  	 						))}
			  	 			</div>
		  	 			</div>
					))
    		: null}
    	</div>
  );
}

export default connect((state) => ({
	menu: state.Globals.menu,
	showBanner: state.App.showBanner
}))(TopMenu)