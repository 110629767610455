import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link, Route, useRouteMatch, useParams, useHistory} from 'react-router-dom'

import {setModalIsOpen} from './../../../actions'

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import PeopleSingleModule from './../PeopleSingleModule'
import PeopleThumb from './../PeopleThumb'

import TestModule from './../TestModule'

function SpeakerArchiveAll(props) {
  // const singleMatch = useRouteMatch("/speakers/:id");

  // useEffect(() => {
  //   setModalIsOpen(singleMatch ? true : false)
  // }, [singleMatch])


  return (
  	  props.archivePosts ? 
	    <div className="PageModule PeopleAllModule">
        {props.slug == '/speakers' ?
    		  <Route path={`/speakers/:id`} render={props => <PeopleSingleModule {...props} parentSlug="/speakers"/>}/>
            
            : null}
				<div className="people-section">
					<div className="col col16 section-title"><div className="inner">Speakers</div></div>
            {props.archivePosts.posts.length > 0 ? props.archivePosts.posts.map((ele, i) => {
              return(
    				    <PeopleThumb key={i+ele.post_name} data={ele} slug={props.slug}/>
    				  )
  			   }) : 
            <div className="col col16">
              {props.query.cat ? <div className="inner not-found">No speakers for "{props.archiveCategories.all.find(ele => ele.slug == props.query.cat).name}"</div> : null}
            </div>
          }
	 	    </div>
	    </div>
      : null
  );
}

export default connect((state, ownProps) => ({
    archivePosts: state.App.pageData[ownProps.slug].data.archiveSpeakers,
    query: state.router.location.query,
    archiveCategories: state.App.pageData[ownProps.slug].data.archiveCategories
}))(SpeakerArchiveAll);
