import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link, Route, useRouteMatch} from 'react-router-dom'

import {setModalIsOpen} from './../../../actions'

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import PeopleSingleModule from './../PeopleSingleModule'
import PeopleThumb from './../PeopleThumb'

function PeopleAllModule(props) {
  const singleMatch = useRouteMatch(props.slug+"/:slug");

  useEffect(() => {
    setModalIsOpen(singleMatch ? true : false)
  }, [singleMatch])

  return (
	    <div className="PageModule PeopleAllModule">
    		<Route path={props.slug+`/:id`} children={({match, ...rest}) => {
              return(
                  <PeopleSingleModule parentSlug={props.slug} slug={singleMatch ? singleMatch.params.slug : null}/>
              )}}/>
	    	{props.archivePosts.tax.map((tax, taxNum) => {
    			var posts = props.archivePosts.posts.filter((ele) => ele.section.term_id === tax.term_id)
    			return(
    				<div className="people-section" key={taxNum}>
    					<div className="col col16 section-title"><div className="inner">{tax.name}</div></div>
  		    			{posts.map((ele, i) => (
  						 	<PeopleThumb key={i} data={ele} slug={props.slug}/>
  					 	))}
				 	</div>
    				)
    			})}
	    </div>
  );
}

export default connect()(PeopleAllModule);
