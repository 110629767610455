import React from 'react';
import {connect} from 'react-redux'
import {Link, Route, useRouteMatch, useParams, useHistory} from 'react-router-dom'

function TestModule() {
  let params = useParams();
  let history = useHistory();

  return (
  	<div style={{position: 'absolute', zIndex: 2000, fontSize: 100}} onClick={() => history.goBack()}>{params.id}</div>
  	)
}

export default connect()(TestModule);