import React, {useEffect, useState} from 'react';
import './index.scss';

import {getApiBase} from './../../helpers'
import {useParams, Route, useLocation, useHistory, useRouteMatch} from 'react-router-dom'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'


import EventArchiveSingle from './../PageModules/EventArchiveSingle'
import SingleEventSpeaker from './../SingleEventSpeaker'
import Footer from './../Footer'

import LazyImageComponent from './../LazyImageComponent'

import {ReactComponent as ArrowIcon} from './../../../assets/icons/arrowswungicon.svg'
import {ReactComponent as IconTime} from './../../../assets/icons/icon-time.svg'
import {ReactComponent as IconLocation} from './../../../assets/icons/icon-location.svg'
import {ReactComponent as IconCal} from './../../../assets/icons/icon-cal.svg'
import {ReactComponent as IconPrice} from './../../../assets/icons/icon-price.svg'
import {ReactComponent as PlusIcon} from './../../../assets/icons/plusicon.svg'

import {ReactComponent as CloseIcon} from './../../../assets/icons/closeicon.svg'


import {setModalIsOpen} from './../../actions'

function SingleEvent(props) {
    let history = useHistory();

    const singleMatch = useRouteMatch(props.dataKey+"/signup");

    useEffect(() => {
      let showSignup = singleMatch ? true : false;
      setModalIsOpen(showSignup);

      if (showSignup) {
        window.hbspt.forms.create({
            portalId: "5509946",
            formId: props.apiData.hubspot_form_id,
            target: '#pitchform_target_div'
        });
      }
    }, [singleMatch])

     useEffect(() => {
        if (props.routerAction === 'POP' && props.lastScroll) window.scrollTo(0, props.lastScroll.val)
        else window.scrollTo(0,0);
      }, [props.dataKey])

  return (
    <div className={`page single-event ${props.mainmenuIsOpen ? 'open' : ''}`}>
        {props.pathname !== '/' && props.pathname == props.dataKey ? 
            <Helmet>
              <title>PSV Academy ∙ {props.apiData.post_title}</title>
              <meta name="description" content={props.apiData.main_content.manchet ? props.apiData.main_content.manchet : props.meta.description_global}/>
            </Helmet>
        : null}

        <div className="back arrow" onClick={() => history.goBack()}><ArrowIcon/></div>

        <div className="PageModule">
          <div className="col col2"/>
          <div className="col col11 content-col col16-l">
              <LazyImageComponent height={props.apiData.image_main.height} width={props.apiData.image_main.width} url={props.apiData.image_main.url} className="hero"/>
              <h1 className="event-title">{props.apiData.post_title}</h1>
              <div className="splitter"/>
              <div className="data">
                <div className="date">
                  <div><IconCal/>{props.apiData.start.date}{props.apiData.end.date !== props.apiData.start.date ? ' - '+props.apiData.end.date : null}</div>
                    {props.apiData.place_google_maps_link ? 
                      <div className="location"><a href={props.apiData.place_google_maps_link} target="_blank"><IconLocation/><div dangerouslySetInnerHTML={{__html: props.apiData.location ? props.apiData.location : props.apiData.location_short}}/></a></div> :
                      <div className="location"><IconLocation/><div dangerouslySetInnerHTML={{__html: props.apiData.location ? props.apiData.location : props.apiData.location_short}}/></div>
                    }
                  {props.apiData.end.date == props.apiData.start.date ? 
                    <div>
                      <IconTime/>{props.apiData.start.time} - {props.apiData.end.time} {props.apiData.cet_cest}
                    </div>
                    : null}
                    {props.apiData.price ? <div><IconPrice/>{props.apiData.price}</div> : null}
                </div>
              </div>
              <div className="manchet">{props.apiData.main_content.manchet}</div>
              <div className="subsection-wrapper double">
                <div className="subsection">
                  <div className="section-title">Why you should attend</div>
                  <div className="content" dangerouslySetInnerHTML={{__html: props.apiData.main_content.why_you_should_attend}}></div>
                </div>
                <div className="subsection program">
                  <div className="section-title">Program</div>
                  <div className="content" dangerouslySetInnerHTML={{__html: props.apiData.main_content.program}}></div>
                </div>
              </div>
              <div className="subsection-wrapper">
                <div className="subsection">
                  <div className="section-title">Speakers</div>
                  <div className="content speakers">
                    {props.apiData.speakers.map((ele, i) => (
                      <SingleEventSpeaker key={i} ele={ele}/>
                      ))}
                  </div>
                </div>
              </div>
              <div className="subsection-wrapper">
                <div className="subsection">
                  <div className="section-title">Info</div>
                  <div className="content" dangerouslySetInnerHTML={{__html: props.apiData.main_content.info}}></div>
                </div>
              </div>
          </div>

          <div className="col col3 join-col">
            <div className="inner">
              <Link to={`${props.dataKey}/signup`}><div className="btn blue join">Sign up</div></Link>
            </div>
          </div>
      </div>

      <Route path={`${props.dataKey}/signup`} children={({match, ...rest}) => {
        return(
          match ?
          <div>
            <Helmet>
              <title>PSV Academy ∙ {props.apiData.post_title} ∙ Signup</title>
              <meta name="description" content={props.apiData.main_content.manchet ? props.apiData.main_content.manchet : props.meta.description_global}/>
            </Helmet>
            <div className={`signupform-wrapper`}>
              <div className="PageModule">
                <div className="col col2"></div>
                <div className="col col11 form-col col16-l">
                  <div id="pitchform_target_div" className="hs_form"/>
                </div>
                <div className="col col3 join-col">
                  <div className="inner">
                    <Link to={props.dataKey}><div className="btn black close"><span>Close</span><CloseIcon/></div></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>  
            : null
        )}}/>

        <Route path={`${props.dataKey}/confirmation`} children={({match, ...rest}) => {
        return(
          match ?
          <div>
            <Helmet>
              <title>PSV Academy ∙ {props.apiData.post_title} ∙ Confirmation</title>
              <meta name="description" content={props.apiData.main_content.manchet ? props.apiData.main_content.manchet : props.meta.description_global}/>
            </Helmet>
            <div className={`signupform-wrapper`}>
              <div className="PageModule">
                <div className="col col2"></div>
                <div className="col col11 form-col col16-l">
                  <p className="confirmation">{props.apiData.confirmation_message}</p>
                </div>
                <div className="col col3 join-col">
                  <div className="inner">
                    <Link to={props.dataKey}><div className="btn black close"><span>Close</span><CloseIcon/></div></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>  
            : null
        )}}/>

        {/*showSignupForm ? 
          <div className={`signupform-wrapper`}>
            <div className="PageModule">
              <div className="col col2"></div>
              <div className="col col11 form-col col16-l">
                <div id="pitchform_target_div" className="hs_form"/>
              </div>
              <div className="col col3 join-col">
                <div className="inner">
                  <div className="btn black close" onClick={() => openSignupModal(false)}><span>Close</span><CloseIcon/></div>
                </div>
              </div>
            </div>
          </div>
        : null*/}

      {props.apiData.similar_events.length > 0 ? 
        <div className="PageModule">
          <div className="col col16 indicator">
            <div className="inner">Outsmart yourself</div>
          </div>
          {props.apiData.similar_events.map((ele, i) => (
            <EventArchiveSingle key={i} data={ele} slug={props.slug}/>
          ))}
        </div>
        : null}

        <Footer/>
    </div>
  );
}

export default connect((state, ownProps) => ({
		apiData: state.App.pageData[ownProps.dataKey].data,
    pathname: state.router.location.pathname,
    mainmenuIsOpen: state.Globals.mainmenuIsOpen,
    lastScroll: state.App.scrollhistory[state.router.location.key],
    routerAction: state.router.action,
    meta: state.Globals.meta
	}))(SingleEvent);
