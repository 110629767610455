import React from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link} from 'react-router-dom'

import LazyImageComponent from './../../LazyImageComponent'

import {ReactComponent as ArrowIcon} from './../../../../assets/icons/arrowswungicon.svg'

function ResourceArchiveSingle(props) {
  let postLink = '/resources/' + props.data.post_name;

  return ( 	
		<div className={`col col4 thumb col8-s show`} key={props.data.post_name}>
			<div className="inner">
				{props.data.image_thumb ? <LazyImageComponent height={props.data.image_thumb.height} width={props.data.image_thumb.width} url={props.data.image_thumb.sizes.medium_large} link={postLink}/> : null}
			</div>
			<div className="title">{props.data.label ? <span className="label">{props.data.label}</span> : ''}<Link to={postLink}>{props.data.description}</Link></div>
			<ul className="tax">
				{props.data.filter ? props.data.filter.map((filterEle, filterIndex) => (
					<li key={filterIndex}><Link to={{search: '?cat='+filterEle.slug, state: {fromTag: true}}}>{filterEle.name}</Link></li>
				)) : null}
			</ul>
			<Link to={postLink} className="link"><ArrowIcon/></Link>
		</div>
  );
}

export default connect()(ResourceArchiveSingle);
