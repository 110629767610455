import React, {useEffect, useState} from 'react';
import './index.scss';

import {getApiBase} from './../../helpers'
import {useParams, useLocation, useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'

import {SingleSectionText} from './sections'
import {SingleSectionQuote} from './sections'
import Footer from './../Footer'

import {ReactComponent as ArrowIcon} from './../../../assets/icons/arrowswungicon.svg'

import ResourceArchiveSingle from './../PageModules/ResourceArchiveSingle'
import CTASplitterModule from './../PageModules/CTASplitterModule'
import SingleEventSpeaker from './../SingleEventSpeaker'

function Single(props) {
    let history = useHistory();

     useEffect(() => {
        if (props.routerAction === 'POP' && props.lastScroll) window.scrollTo(0, props.lastScroll.val)
        else window.scrollTo(0,0);
      }, [props.dataKey])

  return (
    <div className={`page single-resource ${props.mainmenuIsOpen ? 'open' : ''}`}>
        {props.pathname !== '/' && props.pathname == props.dataKey ? 
            <Helmet>
              <title>PSV Academy ∙ {props.apiData.post_title}</title>
              <meta name="description" content={props.apiData.seo.description ? props.apiData.seo.description : props.meta.description_global}/>
            </Helmet>
        : null}

        {/*<div className="back arrow" onClick={() => history.goBack()}><ArrowIcon/></div>*/}
        
        {props.apiData.sections ? props.apiData.sections.map((ele, i) => (
            ele.acf_fc_layout == 'text' ? <SingleSectionText key={i} data={ele}/>
            : ele.acf_fc_layout == 'quote' ? <SingleSectionQuote key={i} data={ele} />
            : null
        )) : null}

        {props.apiData.speakers.length > 0 ?
          <div className="PageModule">
            <div className="col col16 indicator">
              <div className="inner">Related speakers</div>
            </div>
            {props.apiData.speakers.map((ele, i) => (
              <SingleEventSpeaker key={i} ele={ele} layout="narrow"/>
            ))}
          </div>
        : null}

        {props.apiData.related_resources.length > 0 ?
          <div className="PageModule ResourceArchiveAll related">
            <div className="col col16 indicator">
              <div className="inner">Related resources</div>
            </div>
            {props.apiData.related_resources.map((ele, i) => (
              <ResourceArchiveSingle key={i} data={ele}/>
            ))}
          </div>
        : null}

        {props.apiData.cta.show ? 
          <CTASplitterModule  data={props.apiData.cta}/>
          : null
        }

        <Footer/>
    </div>
  );
}

export default connect((state, ownProps) => ({
		apiData: state.App.pageData[ownProps.dataKey].data,
    pathname: state.router.location.pathname,
    mainmenuIsOpen: state.Globals.mainmenuIsOpen,
    lastScroll: state.App.scrollhistory[state.router.location.key],
    routerAction: state.router.action,
    meta: state.Globals.meta
	}))(Single);
