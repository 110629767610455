import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link, Route, useRouteMatch} from 'react-router-dom'

import ArchiveFilter from './../ArchiveFilter'
import EventArchiveAll from './../EventArchiveAll'
import ResourceArchiveAll from './../ResourceArchiveAll'
import SpeakerArchiveAll from './../SpeakerArchiveAll'
import ArchiveSectionSplitter from './../ArchiveSectionSplitter'

function ArchiveModule(props) {
  return (
	    <div className="ArchiveModule">
        	<ArchiveFilter slug={props.slug}/>
	    	{
	    		props.firstModule == 'resources' ?
	    			<div  className="inner">
		        		<ResourceArchiveAll slug={props.slug} data={props.data}/>
		        		<ArchiveSectionSplitter slug={props.slug} data={props.data}/>
		        		<EventArchiveAll slug={props.slug} data={props.data}/>
		        		<SpeakerArchiveAll slug={props.slug} data={props.data}/>
		        	</div>
	        	: props.firstModule == 'events' ? 
	        		<div className="inner">
		        		<EventArchiveAll slug={props.slug} data={props.data}/>
		        		<ArchiveSectionSplitter slug={props.slug} data={props.data}/>
		        		<ResourceArchiveAll slug={props.slug} data={props.data}/>
		        		<SpeakerArchiveAll slug={props.slug} data={props.data}/>
		        	</div>
		        : props.firstModule == 'speakers' ? 
	        		<div className="inner">
		        		<SpeakerArchiveAll slug={props.slug} data={props.data}/>
		        		<ArchiveSectionSplitter slug={props.slug} data={props.data}/>
		        		<EventArchiveAll slug={props.slug} data={props.data}/>
		        		<ResourceArchiveAll slug={props.slug} data={props.data}/>
		        	</div>
    		: null}
	 	</div>
  );
}

export default connect()(ArchiveModule);
