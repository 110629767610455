import React, {useState} from 'react';
import './index.scss';

import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'

import {ReactComponent as MenuIcon} from './../../../assets/icons/plusicon.svg'

function Mainmenu(props) {
	const [mobileSubmenuIdOpenOnMobile, setMobileSubmenuIdOpenOnMobile] = useState(null)
	const toggleMenu = () => {
		props.dispatch({type: 'TOGGLE_MAINMENU'})
	}

	const closeMenu = () => {
		props.dispatch({type: 'CLOSE_MAINMENU'})
	}

	const toggleSubMenu = (i) => {
		setMobileSubmenuIdOpenOnMobile(mobileSubmenuIdOpenOnMobile == i ? null : i)
	}

  return (
  		<div className={`Mainmenu${props.showBanner ? ' showbanner' : ''}${props.mainmenuIsOpen ? ' open' : ''}${props.animateBanner ? ' animate' : ''}`}>
	  		<div onClick={toggleMenu} id="mainmenu-toggle"/>
	    	{props.menu ? 
    			<div className="PageModule menucontainer">
    				<div className="inner">
	    				{props.menu.mainmenu.map((ele, i) => (
			    			<div className={`col col4 col16-m menu-main menu type-${ele.menuitem_type} ${mobileSubmenuIdOpenOnMobile == i ? 'open' : ''}`} key={i}>
			    				{
			    					ele.menuitem_type === 'pagelink' ? 
						  	 			<div className="menuitem"><NavLink key={i} onClick={closeMenu} to={{pathname: `/` + ele.menuitem_page[0].post_name, state: {fromTag: true}}}>{ele.menuitem_page[0].post_title}</NavLink></div>
						  	 		: 
						  	 			<div className={`submenu`}>
						  	 				<div className="menuitem" onClick={() => toggleSubMenu(i)}>{ele.submenu_name}<MenuIcon className="submenu-toggle"/></div>
						  	 					{ele.submenu_items.map((ele2, i2) => (
						  	 							<NavLink key={i2} onClick={closeMenu} className="submenuitem" to={{pathname:`/` + ele2.post_name, state: {fromTag: true}}}>{ele2.post_title}</NavLink>
						  	 						))}
						  	 			</div>
			    				}
		    				</div>
	    					))}
	    				<div className="col col16 menu menu-quicklinks">
							{props.menu.quicklinks.map((ele, id) => {
								const parent = ele.post_type === 'page' ? '' : ele.post_type + '/'
								return (
						  	 		<NavLink key={id} onClick={closeMenu} to={{pathname:`/` + parent + ele.post_name, state: {fromTag: true}}}>{ele.post_title}</NavLink>
								)
							})}
	    				</div>
						<NavLink onClick={closeMenu} to={{pathname:`/` + props.menu.cta_btn.menu_btn_link[0].post_name, state: {fromTag: true}}} className="btn white cta_btn">{props.menu.cta_btn.menu_btn_label}</NavLink>
    				</div>
    			</div>
    		: null}
    	</div>
  );
}

export default connect((state) => ({
	menu: state.Globals.menu,
	mainmenuIsOpen: state.Globals.mainmenuIsOpen,
	showBanner: state.App.showBanner,
	animateBanner: state.App.animateBanner
}))(Mainmenu)