import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link, Route, useRouteMatch} from 'react-router-dom'

import {getApiBase} from './../../../helpers'
import {updateEventsArray} from './../../../actions'

import EventArchiveAll from './../EventArchiveAll'

function Tagcloud(props) {
  return (
	    <div className="PageModule Tagcloud">
	    	<div className="col col16 indicator">
	    		<div className="inner">{props.data.title}</div>
	    	</div>
	    	<div className="col col16 headline">
	    		<div className="inner">{props.data.headline}</div>
	    	</div>
	        <div className="col col16 wrapper">
              {props.data.tags.map((ele, i) => (
                <Link to={`/${props.data.button_link[0].post_name}?cat=${ele.slug}`} key={i} className="tag">{ele.name}</Link>
              ))}
	        </div>
    	 	<Link className="btn black show_all_tags" to={'/'+props.data.button_link[0].post_name}>{props.data.button_label}</Link>
	 	</div>
  );
}

export default connect()(Tagcloud);
