import React, {useState} from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import './index.scss';

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LazyImageComponent from './../../LazyImageComponent'

import {ReactComponent as ArrowIcon} from './../../../../assets/icons/arrowswungicon.svg'

function TestimonialModule(props) {
	const [currentSlide, setCurrentSlide] = useState(0)
	const width = [45,60,80]

    const generateLink = (ele) => {
        var parent = props.data.testimonial[currentSlide].link_to_page[0].post_type == 'page' ? '/' : '/' + props.data.testimonial[currentSlide].link_to_page[0].post_type + '/';
        return parent + props.data.testimonial[currentSlide].link_to_page[0].post_name;
    }

  return (
    <div className="PageModule TestimonialModule">
    	<div className="col col8 img-col col16-s">
    		<div className="inner">
    		{props.data.testimonial.map((ele, id) => (
    				ele.image ? 
                        <div className={`wrap ${id == currentSlide ? 'active' : ''}`} onClick={() => setCurrentSlide(id)} key={id}>
    					   {ele.image ? <LazyImageComponent key={id} height={ele.image.height} width={ele.image.width} url={ele.image.sizes.fullscreen}/> : null}
    				    </div>
                    : null
    			))}
    		</div>
    	</div>
    	<div className="col col8 statement-col col16-s">
    		<div className="inner">
    	        <div className="text statement">{props.data.testimonial[currentSlide].statement}</div>
                <div className="readmore_btn"><Link to={generateLink(props.data.testimonial[currentSlide].link_to_page[0])}>{props.data.testimonial[currentSlide].link_label}<ArrowIcon/></Link></div>
    			<div className="slidecounter">
			    	<div className="inner">
			    		<ul>
			    			{props.data.testimonial.map((ele, i) => 
			    				ele.image ? <li key={i} className={currentSlide == i ? 'active' :  ''} onClick={() => setCurrentSlide(i)}/> : null
		    				)}
	    				</ul>
			    	</div>
		    	</div>
    		</div>
    	</div>
    </div>
  );
}

export default connect()(TestimonialModule);
