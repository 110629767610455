import React from 'react';
import './App.scss';

import {connect} from 'react-redux'

import SiteLoader from './SiteLoader'
import Globals from './Globals'
import Routes from './Routes'
import ScreenSaver from './ScreenSaver'
import AnimatedScrollHandler from './AnimatedScrollHandler'

function App(props) {
  return (
	    <div className={`App ${props.isMobile ? 'mobile' : 'desktop'}`}  /*onMouseMove={(e) => props.dispatch({type: 'SET_MOUSE_POS', mousePos: {x: e.clientX, y: e.clientY}})}*/>
	    	{/*!props.isMobile ? <ScreenSaver/> : null*/}
	    	<SiteLoader/>
	        <Globals/>
  			{props.routesHasBeenSet ? <Routes/> : null}
      		<AnimatedScrollHandler/>
	    </div>
  );
}

export default connect((state) => ({
	routesHasBeenSet: state.Routes.routesHasBeenSet,
	globalsHasBeenSet: state.Globals.globalsHasBeenSet,
	isMobile: state.Device.isMobile
}))(App);