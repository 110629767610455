const initialState = {
	pageIsLoading: true,
	pageData: null,
	pagesToShow: [],
	scrollhistory: [],
	currentRouterKey: null,
	scrollPosition: 0,
	scrollDest: null,
	scrollIsAnimating: false,
	modalIsOpen: false,
	mousePos: {x: window.innerWidth/2, y: window.innerHeight/2},
	showBanner: true,
	animateBanner: false
}

const App = (state = initialState, action) => {
	switch(action.type) {

		case 'PAGE_IS_LOADING':
			return {
				...state,
				pageIsLoading: true,
			}
		break;

		case 'PAGE_HAS_LOADED':
			const currentPageKey = action.pageObj.url
			return {
				...state,
				pageIsLoading: false,
				pagesToShow: [{key: currentPageKey, component: action.pageObj.component}],
				pageData: {
					...state.pageData,
					[currentPageKey]: {
						component: action.pageObj.component,
						menuName: action.pageObj.menuname,
						data: action.pageObj.data
					}
				}
			}
		break;

		case 'REPLACE_ARCHIVE_POSTS':
			return {
				...state,
				pageData: {
					...state.pageData,
					[action.pageKey]: {
						...state.pageData[action.pageKey],
						data: {
							...state.pageData[action.pageKey].data,
							archiveEvents: action.data.archiveEvents,
							archiveResources: action.data.archiveResources,
							archiveSpeakers: action.data.archiveSpeakers
						}
					}
				}
			}
		break;

		case 'UPDATE_RESOURCES_POSTS':
			var allPostsLoaded = state.pageData[action.pageKey].data.archiveResources.posts.length + action.newPosts.length == state.pageData[action.pageKey].data.archiveResources.totalNumOfPosts ? true : false;
			return {
				...state,
				pageData: {
					...state.pageData,
					[action.pageKey]: {
						...state.pageData[action.pageKey],
						data: {
							...state.pageData[action.pageKey].data,
							archiveResources: {
								...state.pageData[action.pageKey].data.archiveResources,
								posts: [...state.pageData[action.pageKey].data.archiveResources.posts, ...action.newPosts],
								allPostsLoaded: allPostsLoaded
							}
						}
					}
				}
			}
		break;

		case 'UPDATE_EVENTS_POSTS':
			var allPostsLoaded = state.pageData[action.pageKey].data.archiveEvents.posts.length + action.newPosts.length == state.pageData[action.pageKey].data.archiveEvents.totalNumOfPosts ? true : false;

			return {
				...state,
				pageData: {
					...state.pageData,
					[action.pageKey]: {
						...state.pageData[action.pageKey],
						data: {
							...state.pageData[action.pageKey].data,
							archiveEvents: {
								...state.pageData[action.pageKey].data.archiveEvents,
								posts: [...state.pageData[action.pageKey].data.archiveEvents.posts, ...action.newPosts],
								allPostsLoaded: allPostsLoaded
							}
						}
					}
				}
			}
		break;

		case 'NO_PAGE_FOUND':
			return {
				...state,
				pageIsLoading: false,
				pagesToShow: []
			}
		break;

		case 'SET_SCROLL_POSITION':
			return {
				...state,
				scrollPosition: action.scrollPosition
			}
		break;

		case 'SET_CURRENT_SCROLL':
			const nextRouterKey = action.nextRouterKey ? action.nextRouterKey : 'init';

			return {
				...state,
				currentRouterKey: nextRouterKey,
				scrollhistory: {
					...state.scrollhistory,
					[state.currentRouterKey]: {
						val: window.scrollY
					}
				}
			}
		break;

		case 'UPDATE_ROUTER_KEY':
			return {
				...state,
				currentRouterKey: action.key
			}
		break;

		case 'SET_SCROLL_DEST':
			return {
				...state,
				scrollDest: action.scrollDest,
				scrollIsAnimating: true,
			}
		break;

		case 'RESET_ANIMATED_SCROLL':
			return {
				...state,
				scrollIsAnimating: false,
				scrollDest: state.scrollPosition,
			}
		break;

		case 'SET_MODAL_IS_OPEN':
			return {
				...state,
				modalIsOpen: action.state
			}
		break;

		case 'SET_MOUSE_POS':
			return {
				...state,
				mousePos: action.mousePos
			}
		break;

		case 'TOGGLE_SHOW_BANNER':
			let newVal = action.val ? action.val : !state.showBanner
			
			return {
				...state,
				showBanner: newVal,
				animateBanner: true
			}
		break;

		case 'FORCE_HIDE_BANNER':
			return {
				...state,
				showBanner: false,
				animateBanner: false
			}
		break;

		case 'SET_ANIMATE_BANNER':
			return {
				...state,
				animateBanner: action.val
			}
		break;

		default:
			return state
	}
}

export default App