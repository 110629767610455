import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link, Route, useRouteMatch} from 'react-router-dom'

import {getApiBase} from './../../../helpers'
import {updateEventsArray} from './../../../actions'

import EventArchiveSingle from './../EventArchiveSingle'

function EventArchiveAll(props) {
  const initialLoad = useRef(true);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [currentCatIndex, setCurrentCatIndex] = useState(null)

  const fetchData = async (query) => {
        setIsFetchingData(true);

        let queryToGet = '?posts_per_page='+props.postPerPage;
        queryToGet += query.page ? '&page='+query.page : '';

        const url = getApiBase()+'/wp-json/data/v2/geteventarchive'+queryToGet;
        const response = await fetch(url);
        const jsonResponse = await response.json();

        // add to archivedata in redux
          props.dispatch({
              type: 'UPDATE_EVENTS_POSTS',
              newPosts: jsonResponse,
              pageKey: props.slug
          })

        setIsFetchingData(false);

        props.dispatch({
          type: 'UPDATE_ROUTER_KEY',
          key: props.currentRouterKey
        })
  }

  useEffect(() => {
    if (props.slug == props.currentRouterPathname && props.data.acf_fc_layout == 'events_past') {
      if (initialLoad.current) {
        initialLoad.current = false;
        // setCurrentPage(props.query.page ? parseInt(props.query.page) : 1);
      } else {
        if (props.query) fetchData(props.query);
      }
    }
  }, [props.query])

  let currentMonthShowing;

  return (
			props.archivePosts ? 
	    <div className="PageModule EventArchiveAll">
        <div className="col col16 indicator">
          <div className="inner">{props.archivePosts.previousEvents ? `Expired sessions`: ` Upcoming sessions`}</div>
        </div>
            {props.archivePosts.posts.length > 0 ? 
              props.archivePosts.posts.map((ele, i) => {
                let showMonthIndicator = false;
                let date = ele.start.date;
                let currentMonth = date.substr(0,date.indexOf(' '));
                if (currentMonth !== currentMonthShowing) {
                  currentMonthShowing = currentMonth;
                  showMonthIndicator = true;
                }

                return(
                  <div className="archive-row" key={i}>
                    {/*showMonthIndicator ? <div className="col col16 indicator"><div className="inner">{currentMonthShowing}</div></div> : null*/}
    					 	    <EventArchiveSingle data={ele} slug={props.slug}/>
                  </div>
    				 	   )
              }) : 
              <div className="col col16">
                {props.query.cat ? <div className="inner not-found">No upcoming sessions for "{props.archiveCategories.all.find(ele => ele.slug == props.query.cat).name}"</div> : null}
              </div>}
              {props.data ? 
                props.data.acf_fc_layout == 'events_past' ? 
                  <div className="nav-wrapper">
                    <Link to={`/${props.data.link_to_upcoming_events[0].post_name}`} className="btn border">{props.data.cta_label ? props.data.cta_label : '[cta label missing in backend]'}</Link>
                    {!props.archivePosts.allPostsLoaded ? <Link className="btn black loadmore_btn" to={`?page=${props.currentRouterPage ? parseInt(props.currentRouterPage)+1 : '2'}`}>Load more</Link> : null}
                  </div> :
                props.data.acf_fc_layout == 'events_upcoming' ? 
                  Object.keys(props.query).length == 0 ?
                    <div className="nav-wrapper">
                      <Link to={`/${props.data.link_to_past_events[0].post_name}`} className="btn black">{props.data.cta_label ? props.data.cta_label : '[cta label missing in backend]'}</Link>
                    </div> : 
                    null : null : null}
		 	</div>
      : null
  );
}

export default connect((state, ownProps) => ({
  archiveCategories: state.App.pageData[ownProps.slug].data.archiveCategories,
  archivePosts: state.App.pageData[ownProps.slug].data.archiveEvents,
  postPerPage: state.App.pageData[ownProps.slug].data.posts_per_page,
  currentRouterPage: state.router.location.query ? state.router.location.query.page ? state.router.location.query.page : null : null,
  query: state.router.location.query,
  currentRouterKey: state.router.location.key,
  currentRouterPathname: state.router.location.pathname
}))(EventArchiveAll);
