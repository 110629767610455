import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link, Route, useRouteMatch, useLocation} from 'react-router-dom'

import {getApiBase} from './../../../helpers'
import {updateEventsArray, toggleShowBanner} from './../../../actions'

import EventArchiveAll from './../EventArchiveAll'

import {ReactComponent as IconClose} from './../../../../assets/icons/icon-close.svg'
import {ReactComponent as IconDots} from './../../../../assets/icons/icon-dots.svg'


function ArchiveFilter(props) {
  const initialLoad = useRef(true);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [currentTagIsInSelectedTags, setCurrentTagIsInSelectedTags] = useState(false);
  const [currentTagPosInAllTags, setCurrentTagPosInAllTags] = useState();
  const [shouldBreak, setShouldBreak] = useState(false)

  let location = useLocation();

  useEffect(() => {
    const breakPoint = 600;
    setShouldBreak(props.screenWidth <= breakPoint);
  }, [props.screenWidth])

  const fetchData = async (query) => {
        setIsFetchingData(true);
        // const url = getApiBase()+'/wp-json/data/v2/getarchive?page='+page+'&posts_per_page='+props.archivePosts.posts_per_page;
        let queryToGet = query.cat ? '?cat='+query.cat : '?reset='+props.slug.substr(1,99);
        queryToGet += '&posts_per_page='+props.postPerPage;
        queryToGet += query.page ? '&page='+query.page : '';

        const url = getApiBase()+'/wp-json/data/v2/getarchive'+queryToGet;
        const response = await fetch(url);
        const jsonResponse = await response.json();

        if (query.page) {

          // add to archivedata in redux
          props.dispatch({
              type: 'UPDATE_RESOURCES_POSTS',
              newPosts: jsonResponse.archiveResources.posts,
              pageKey: props.slug
          })
        } else {

          // replace all archivedata in redux
            props.dispatch({
              type: 'REPLACE_ARCHIVE_POSTS',
              data: jsonResponse,
              pageKey: props.slug
            })

          // scroll to top
            const ele = document.getElementsByClassName('ArchiveModule')[0];
            let offsetTop = {desktop: 100, mobile: 70}

            const scrollDest = ele.getBoundingClientRect().y  + window.pageYOffset - (shouldBreak ? offsetTop.mobile : offsetTop.desktop);
            window.scrollTo(0,scrollDest);

            setTimeout(() => {
              // toggleShowBanner(false);
              props.dispatch({
                type: 'FORCE_HIDE_BANNER',
                val: false
              })
            }, 20);
        }

        setIsFetchingData(false);

        props.dispatch({
          type: 'UPDATE_ROUTER_KEY',
          key: props.currentRouterKey
        })
  }

  useEffect(() => {
    setShowAllTags(false);

    if (props.slug == props.currentRouterPathname) {
      if (initialLoad.current) {
        initialLoad.current = false;
      } else {
        if (props.query && location.state.fromTag) fetchData(props.query);
      }

      let indexOfTagInSelected = props.archiveCategories.selected.findIndex(selEle => selEle.slug == props.query.cat);
      let isInSelTags = indexOfTagInSelected > -1 ? true : false;
      setCurrentTagIsInSelectedTags(isInSelTags);
    }
  }, [props.query])

  let indexOfTagInAll = props.archiveCategories.all.findIndex(ele => ele.slug == props.query.cat);

  return (
	    <div className="PageModule ArchiveFilter">
        <div className="col col16 wrapper">
            <div className="intro">Browse by tag</div>
            <Link to={{search: '', state: {fromTag: true}}} className={`tag ${!props.query.cat ? ' active' : ''}`}>All</Link>
            {!currentTagIsInSelectedTags && props.query.cat ? <Link to={{search: '?cat='+props.query.cat, state: {fromTag: true}}} className="tag active">{props.archiveCategories.all.find(ele => ele.slug == props.query.cat).name}</Link> : null}
              {props.archiveCategories ? props.archiveCategories.selected.map((ele, i) => (
                <Link to={{search: '?cat='+ele.slug, state: {fromTag: true}}} key={i} className={`tag ${props.query.cat == ele.slug ? ' active' : ''}`}>{ele.name}</Link>
              )) : null}
            {!showAllTags ? <div className="show_all_tags tag" onClick={() => setShowAllTags(!showAllTags)}><IconDots/></div> : null}
            {showAllTags ? props.archiveCategories.all.map((ele, i) => {
              let isInSelectedTags = props.archiveCategories.selected.findIndex(selEle => selEle.slug == ele.slug);
              let isSpeakerTag = props.archiveCategories.speakers.findIndex(speakerEle => speakerEle == ele.term_id);
              let isCurrentSelectedTag = i == indexOfTagInAll ? true : false;
              return isInSelectedTags < 0 && isSpeakerTag < 0 && !isCurrentSelectedTag ? <Link to={{search: '?cat='+ele.slug, state: {fromTag: true}}} key={i} className={`tag ${props.query.cat == ele.slug ? ' active' : ''}`}>{ele.name}</Link> : null
            }): null}
            {showAllTags ? <div className="show_all_tags tag active" onClick={() => setShowAllTags(!showAllTags)}><IconClose/></div> : null}
        </div>
		 	</div>
  );
}

export default connect((state, ownProps) => ({
  archiveCategories: state.App.pageData[ownProps.slug].data.archiveCategories,
  postPerPage: state.App.pageData[ownProps.slug].data.posts_per_page,
  query: state.router.location.query,
  currentRouterKey: state.router.location.key,
  currentRouterPathname: state.router.location.pathname,
  screenWidth: state.Device.size.width
}))(ArchiveFilter);
