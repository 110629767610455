import React from 'react'

import parse, {domToReact} from 'html-react-parser'

export function getApiBase() {
  return process.env.NODE_ENV === 'production' ? 'https://cms.preseedventures.dk/academy' : 
	// return process.env.NODE_ENV === 'production' ? 'https://preseedventures.dk/api' : 
  // 'https://preseedventures.dk/api';
  'https://cms.preseedventures.dk/academy';
	// 'http://preseed.troelsknud.dk/api';
	// 'http://dev.preseed-backend';
}

export const convertHTMLStringToReact = (htmlString) => {
    const options = {
      replace: ({attribs, children}) => {
        if (!attribs) return;

        if (attribs.href) {
          return <div>{domToReact(children, options)}</div>
        }
      }
    }

   return parse(htmlString, options)
}