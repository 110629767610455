import {store} from './../config/configureStore.js'
let bannerTimer;

export const animateScroll = (scrollDest) => {
    store.dispatch({type: 'RESET_ANIMATED_SCROLL'})

    setTimeout(() => {
        store.dispatch({type: 'SET_SCROLL_DEST', scrollDest: scrollDest})
    }, 10)
}

export const setModalIsOpen = (val) => {
	if (val) document.body.classList.add('modal-open');
	else document.body.classList.remove('modal-open');
	store.dispatch({
      type: 'SET_MODAL_IS_OPEN',
      state: val
    })
}

export const setDeviceToMobile = (isMobile) => {
    if (isMobile) document.body.classList.add('mobile');
    else document.body.classList.remove('mobile');
    
    store.dispatch({
        type: 'SET_DEVICE_TO_MOBILE',
        isMobile: isMobile
    })
}

export const setDeviceSize = (width, height) => ({
    type: 'SET_DEVICE_SIZE',
    width: width,
    height: height
})

export const updateEventsArray = (newEvents, slug) => ({
    type: 'UPDATE_EVENTS_ARRAY',
    newEvents: newEvents,
    pageKey: slug
})

export const toggleShowBanner = (val) => {
    store.dispatch({
        type: 'TOGGLE_SHOW_BANNER',
        val: val
    })

    clearTimeout(bannerTimer);

    bannerTimer = setTimeout(() => {
        store.dispatch({
            type: 'SET_ANIMATE_BANNER',
            val: false
        })        
    }, 300)
}