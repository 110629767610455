// import ReactGA from 'react-ga';
import {history} from './configureStore'

export const configureAnalytics = () => {
	// ReactGA.initialize('UA-115182529-2');
	history.listen(location => {
		// ReactGA.set({page: location.pathname});
		// ReactGA.pageview(location.pathname);
		
		if (window.gtag) {
			window.gtag('js', new Date());
      		window.gtag('config', 'UA-115182529-3', {'page_path': location.pathname});
		}
	})
}