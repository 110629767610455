import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Slider from 'react-slick'
import tinycolor from 'tinycolor2'

import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

import {ReactComponent as ArrowIcon} from './../../../../assets/icons/arrowswungicon.svg'

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import LazyImageComponent from './../../LazyImageComponent'

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowIcon
      className={`${className} ${props.active ? 'active' : ''}`}
      style={{ ...style}}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowIcon
      className={`${className} ${props.active ? 'active' : ''}`}
      style={{ ...style}}
      onClick={onClick}
    />
  );
}

const breakPoint = 800;


function CasesSelectedModule(props) {
	const [shouldBreak, setShouldBreak] = useState(false)
	const [currentSlide, setCurrentSlide] = useState(0);
	const [mouseIsOver, setMouseIsOver] = useState(false);
	const [displayLeftArrow, setDisplayLeftArrow] = useState(false);

	const sliderRef = useRef(null);

	useEffect(() => {
		setShouldBreak(props.screenWidth <= breakPoint);
	}, [props.screenWidth])

	const sliderSettings = {
		infinite: false, 
		speed: shouldBreak ? 300 : 600,
		slidesToShow: shouldBreak ? 1.2 : 1,
		slidesToScroll: 1,
		nextArrow: <NextArrow active={mouseIsOver && !displayLeftArrow ? true : false}/>,
		prevArrow: <PrevArrow active={mouseIsOver && displayLeftArrow ? true : false}/>,
		beforeChange: (current, next) => setCurrentSlide(Math.ceil(next))
	};

	const movingMouse = (e) => {
		setDisplayLeftArrow(e.clientX < window.innerWidth/2)
	}

	const gotoSlide = (i) => {
		sliderRef.current.slickGoTo(i);
	}

  return (
    <div className="PageModule CasesSelectedModule" onMouseMove={(e) => movingMouse(e)} onMouseLeave={() => setMouseIsOver(false)} onMouseEnter={() => setMouseIsOver(true)}>
    	<div className="moduleHeader">
	    	<div className="col col5 col10-s">
		    	<div className="inner">
		    		<div className="title">Highlighted Cases</div>
		    	</div>
	    	</div>
	    	<div className="col col3 slidecounter">
		    	<div className="inner">
		    		<ul>{props.data.map((ele, i) => <li key={i} onClick={() => gotoSlide(i)} className={currentSlide == i ? 'active' :  ''}/>)}</ul>
		    	</div>
	    	</div>
	    	<div className="col col8 col6-s readmore">
		    	<div className="inner">
		    		<div><Link className="btn-viewall" to={`/${props.viewAll ? props.viewAll.post_name : ''}${props.viewAllFilter ? '?fund='+props.viewAllFilter.slug : ''}`}><span>View all</span></Link></div>
		    	</div>
	    	</div>
    		<div className="col col16 breaker"><div className="inner"/></div>
    	</div>
    	<Slider {...sliderSettings} ref={sliderRef}>
	    	{props.data.map((ele, i) => (
			 	<div className="slide" key={i}>
			 		<div className="inner">
				 		<div className="col col7 text">
				 			<div className="description">{ele.description}</div>
				 		</div>
				 		<div className="col col9 images col16-s">
    						{ele.mood_image ? <LazyImageComponent height={ele.mood_image.height} width={ele.mood_image.width} url={ele.mood_image.sizes.fullscreen} className="mood"/> : null}
    						<div className="brandcolor" style={{backgroundColor: `${ele.brandcolor}`}}>
    							<div className={`inner ${tinycolor(ele.brandcolor ? ele.brandcolor : '#000').isLight() ? 'bright' : 'dark'}`}>{ele.description}</div>
    						</div>
				 			{ele.logo ? <div className="logo"><img src={ele.logo.url}/></div> : null}
				 		</div>
			 		</div>
		 		</div>
		 	))}
    	</Slider>
    </div>
  );
}

export default connect(state => ({
	screenWidth: state.Device.size.width
}))(CasesSelectedModule);
