import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

function ArchiveSectionSplitter(props) {

let catEle = props.archiveCategories.all.find(ele => ele.slug == props.query.cat);
  return (
	    props.query.cat ? 
        <div className="PageModule ArchiveSectionSplitter">
          <div className="col col16">Other stuff related to "{catEle.name}"</div>
  		 	</div>
      : null
  );
}

export default connect((state, ownProps) => ({
  archiveCategories: state.App.pageData[ownProps.slug].data.archiveCategories,
  query: state.router.location.query,
}))(ArchiveSectionSplitter);
