import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link, Route, useRouteMatch} from 'react-router-dom'

import {getApiBase} from './../../../helpers'
import {updateEventsArray} from './../../../actions'

import EventArchiveSingle from './../EventArchiveSingle'

function EventsUpcomingTeaser(props) {
  return (
    <div className="PageModule EventsUpcomingTeaser">
      <div className="col col16 indicator">
          <div className="inner">Upcoming sessions</div>
        </div>
      {props.archiveEvents ? props.archiveEvents.posts.map((ele, i) => (
        <EventArchiveSingle key={i} data={ele} slug={props.slug}/>
      )) : null}
      <div className="nav-wrapper">
        <Link to={`/${props.data.link_to_events_page[0].post_name}`} className="btn black">{props.data.cta_label ? props.data.cta_label : '[cta label missing in backend]'}</Link>
      </div>
    </div>
  );
}

export default connect((state, ownProps) => ({
  archiveEvents: state.App.pageData[ownProps.slug].data.archiveEvents
}))(EventsUpcomingTeaser);
