import React, {useEffect, useState} from 'react';
import './index.scss';

import {Link} from 'react-router-dom'

import LazyImageComponent from './../LazyImageComponent'

import {ReactComponent as PlusIcon} from './../../../assets/icons/plusicon.svg'


function SingleEventSpeaker({ele, layout}) {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className={`SingleEventSpeaker${layout ? ' '+layout : ''}`}>
        <Link to={{pathname: '/speakers/'+ele.post_name, state: {prevPath: 'internal'}}}>
          <div className="thumb">
            <LazyImageComponent height={ele.image.height} width={ele.image.width} url={ele.image.url}/>
            <PlusIcon/>
          </div>
        </Link>
          <div className="data name">{ele.post_title}</div>
          <div className="data title" dangerouslySetInnerHTML={{__html: ele.title}}/>
          <div className={`description${showInfo ? ' open' : ''}`}>{ele.description}<div className="overlay"><div className="expand-btn" onClick={() => setShowInfo(true)}><span>+</span></div></div></div>
    </div>
  )
}

export default SingleEventSpeaker;
