import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';

import {Link} from 'react-router-dom'

function WinbatsBreaker(props) {
    const numOfDots = 3;
    const letters = ['q','w','e','r','t','y','u','i','o','p','å','ä','s','d','f','g','h','j','k','l','æ','ø','z','x','c','v','b','n','m'];
    const [Dots, setDots] = useState([])
    const colors = ['#FF5150', '#1801FF', '#F88DD4', '#F8D631', '#fff']
    const [showMsgBox, setShowMsgBox] = useState(false)
    const [msgToShowInBox, setMsgToShowInBox] = useState(null)
    const [timeoutTimer, setTimeoutTimer] = useState();

    const getRandomColor = () => Math.floor(Math.random()*colors.length)
    const getRandomLetter = () => Math.floor(Math.random()*letters.length)
    const getRandomPos = () => {
                    return ({
                        x: Math.random()*window.innerWidth-120,
                        y: Math.random()*200
                    })
                }

    useEffect(() => {
        let dotsCopy = []
        for (let i = 0; i < numOfDots; i++) {
            let dot = {
                pos: getRandomPos(),
                color: getRandomColor(),
                letter: getRandomLetter()
            }
            dotsCopy.push(dot)
        };
        setDots(dotsCopy)
    }, [props.slug])

    const updateDot = (dotI) => {
        setDots(
            prevDots => prevDots.map((ele, i) => dotI == i ? {...ele, color: getRandomColor(), letter: getRandomLetter()} : ele)
        )
    }

    const createDot = (e) => {
        let numOfDots = 1;
        let newDots = [];
        for (var i = 0; i < numOfDots; i++) {
            newDots.push({
                pos: getRandomPos(),
                color: getRandomColor(),
                letter: getRandomLetter()
            })
        }

        setDots([...Dots, ...newDots])


        var messages = [
            {time: '20', msg: 'We´re really glad you like our stickers! We do to!'},
            {time: '50', msg: 'Have you seen what else we have on our site? There´s a lot of good stuff.'},
            {time: '75', msg: 'Ok, that`s a lot of stickers!'},
            {time: '100', msg: 'What about signing up for our newsletter then? It`s right there in the footer'},
            {time: '150', msg: 'CONGRATS, YOU REACHED 150 STICKERS!'},
            {time: '175', msg: '175 and still clicking, OMG!'},
            {time: '200', msg: 'We`re sorry, but we don`t have anything else for you...'},
            {time: '230', msg: 'PLEASE come to one of our sessions man, or check out our other resources. You see the menu in the upper right corner?'},
            {time: '300', msg: '300 AND STILL LIKING THEM STICKERS HUH! Go work on your startup instead!'},
            {time: '350', msg: 'If you keep on clicking I will remove them all!'},
            {time: '380', msg: '4reals!!!!'},
            {time: '410', msg: 'Last warning!'},
            {time: '450', msg: 'LAST WARNING!'},
            {time: '500', msg: 'You thought I would never do it, didn`t you...? But you did get 500 stickers, not bad!', reset: true},
        ]

        var found;

         if (found = messages.find(ele => ele.time == Dots.length)) {
            clearTimeout(timeoutTimer)

            setShowMsgBox(true)
            setMsgToShowInBox(found.msg)

            var currentTimer = setTimeout(() => {
                setShowMsgBox(false)
            }, 5000)

            if (found.reset) setDots([])

            setTimeoutTimer(currentTimer);
         }
    }

  return (
    <div className={`PageModule WinbatsBreaker`} onClick={(e) => createDot(e)}>
        {Dots.map((ele, i) => <div key={i} className="dot" onMouseEnter={() => updateDot(i)} style={{backgroundColor: colors[ele.color], left: ele.pos.x, top: ele.pos.y}}><div className="inner">{letters[ele.letter]}</div></div>)}
        <div className={`msgbox ${showMsgBox ? 'show': 'hide'}`}><span>{msgToShowInBox}</span></div>
    </div>
  );
}

export default connect(state => ({
    pathname: state.router.location.pathname
}))(WinbatsBreaker);
