import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link, useHistory, useParams} from 'react-router-dom'

import LazyImageComponent from './../../LazyImageComponent'
import {ReactComponent as CloseIcon} from './../../../../assets/icons/closeicon.svg'
import {ReactComponent as Arrow} from './../../../../assets/icons/arrowswungicon.svg'


import {setModalIsOpen} from './../../../actions'

function PeopleSingleModule(props) {
	const [showMail, setShowMail] = useState(false)
	const [currentSlide, setCurrentSlide] = useState(false);
	const [layout, setLayout] = useState(1);
	const [singleData, setSingleData] = useState(null);
	const [prevPathIsInternal, setPrevPathIsInternal] = useState();

	let history = useHistory()
	let {id} = useParams();

	useEffect(() => {
	    const prevPath = props.location.state ? props.location.state.prevPath : null;
	    setPrevPathIsInternal(prevPath == 'internal' ? true : false);

	    setModalIsOpen(true)
		setLayout(Math.ceil(Math.random()*4))

	    return() => {
	    	setModalIsOpen(false)
	    }

	  }, [])

	useEffect(() => {
		let dataForPerson = props.archivePostsAll.find((ele) => ele.post_name == id)
		setSingleData(dataForPerson)
	}, [id])

  return (
  	singleData ?
	  	<div className="PeopleSingleModule">
		  	<div className="inner">
		  		<div className={`col col7 images col16-l layout${layout}`}>
					{singleData.hoverImages ? singleData.hoverImages.map((ele, i) => (
						<div className={`wrap ${ele.height > ele.width ? 'vert' : 'hori'} ${i == currentSlide ? 'active' : ''}`} key={i} onClick={() => setCurrentSlide(i)}>
	    					<LazyImageComponent height={ele.height} width={ele.width} url={ele.sizes.large}/>
						</div>
						))
					: null}
					<div className={`wrap ${singleData.image.height > singleData.image.width ? 'vert' : 'hori'} ${currentSlide == singleData.hoverImages.length ? 'active' : ''}`} key={singleData.hoverImages.length} onClick={() => setCurrentSlide(singleData.hoverImages)}>
	    				<LazyImageComponent height={singleData.image.height} width={singleData.image.width} url={singleData.image.sizes.large}/>
					</div>
				</div>
				<div className="col col9 content col16-l">
					<h2 className="name">{singleData.post_title}</h2>
					{singleData.title ? <div className="data" dangerouslySetInnerHTML={{__html: singleData.title}}/> : null}
					{singleData.phone ? <div className="data">{singleData.phone}</div> : null}
					{singleData.email ? <div className="data email">
						{showMail ?	<a href={`mailto:${singleData.email}`}>{singleData.email}</a>
						: <span onMouseEnter={() => setShowMail(true)}>write me an email</span>}
						</div>
					: null}
					<div className="text" dangerouslySetInnerHTML={{__html: singleData.text}}/>
					{singleData.some ? 
						<ul className="some">
							{singleData.some.map((ele, i) => (
								<li key={i}><a href={ele.link} target="_blank">{ele.name}</a></li>
							))}
						</ul>
						: null
					}
					{singleData.speakerevents.length > 0 ? 
						<div className="events">
							<div className="title">Upcoming events with {singleData.post_title}</div>
							<ul>
							{singleData.speakerevents.map((ele, i) => (
								<li><Link to={'/events/'+ele.post_name}><span>{ele.post_title}</span><Arrow/></Link></li>
							))}
							</ul>
						</div>

					: null}
					{singleData.link_to_resource_tag ? 
						<div className="resources">
							<div className="title">Resources with {singleData.post_title}</div>
							<Link className="resource" to={'/resources?cat='+singleData.link_to_resource_tag.slug}><span>View the resources</span><Arrow/></Link>
							</div>
					: null}
				</div>
				{prevPathIsInternal ? 
					<div onClick={() => history.goBack()}><CloseIcon/></div> 
					: <Link to="/speakers"><CloseIcon/></Link>
				}

		  	</div>
	  	</div>
  		: null
  );
}

export default connect((state, ownProps) => {
		const archivePostsAll = state.App.pageData[ownProps.parentSlug].data.archiveSpeakers ? state.App.pageData[ownProps.parentSlug].data.archiveSpeakers.posts : null;

		return({
			archivePostsAll: archivePostsAll
		})}
	)(PeopleSingleModule);
