import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux'
import './index.scss';

function ScreenSaver(props) {
	const [loaded, setLoaded] = useState(false);
	const [emojis, setEmojis] = useState([]);
	const [timer, setTimer] = useState();
	const [emojiTimer, setEmojiTimer] = useState();
	const [mousePosToUse, setMousePosToUse] = useState({x: window.innerWidth/2, y: window.innerHeight/2})
	const [screensaverStartTime, setScreensaverStartTime] = useState(null);

	const letters = ['q','w','e','r','t','y','u','i','o','p','å','ä','s','d','f','g','h','j','k','l','æ','ø','z','x','c','v','b','n','m'];

	const emojisRef = useRef(emojis);
	emojisRef.current = emojis;

	const screensaverStartTimeRef = useRef(screensaverStartTime);
	screensaverStartTimeRef.current = screensaverStartTime;

	const timerRef = useRef(timer);
	timerRef.current = timer;

	const emojiTimerRef = useRef(emojiTimer);
	emojiTimerRef.current = emojiTimer;

	useEffect(() => {
		clearTimeout(timerRef.current);
		clearTimeout(emojiTimerRef.current);

		setEmojis([])

		let t = setTimeout(() => {
			setMousePosToUse(props.mousePos)
			setScreensaverStartTime(Date.now());
			advanceTimer();
			createEmoji();
		}, 90000)

		setTimer(t);

	}, [props.mousePos])

	const createEmoji = () => {
		let distance = 1000;
		const randX = (Math.random()*2-1)*distance;
		const randY = (Math.random()*2-1)*distance;
		const dir = Math.random()*2-1;

		let newEmoji = {
			destX: randX > 0 ? randX + window.innerWidth/2 + distance/2 : randX - window.innerWidth/2 - distance/2,
			destY: randY > 0 ? randY + window.innerHeight/2 + distance/2 : randY - window.innerHeight/2 - distance/2,
			dir: dir > 0 ? 'forward' : 'backwards',
			letter: Math.floor(Math.random()*letters.length),
			active: false,
			isThisSpecial: Math.random() > 0.9 ? true : false,
			timeOfBirth: Date.now()
		}

		let emojisCopy = [...emojisRef.current, newEmoji];
		setEmojis(emojisCopy)

		setTimeout(() => {
			let emojisCopyStarted = emojisRef.current.map((ele, i) => {
				if (i === emojisRef.current.length - 1) return {...ele, active: true}
				return ele;
			});

			setEmojis(emojisCopyStarted)
		},20)
	}

	const removeOldEmojis = () => {
		let secondsToSurvive = 30;
		setEmojis(prevState => prevState.filter(ele => (Date.now()-ele.timeOfBirth)/1000 < secondsToSurvive))
	}

	const advanceTimer = () => {
		let timeSinceStartOfScreensaver = (Date.now() - screensaverStartTimeRef.current) / 25;
		let valToUse = timeSinceStartOfScreensaver < 4200 ? timeSinceStartOfScreensaver : 4200;

		let createEmojiTimer = setTimeout(() => {
			removeOldEmojis()
			createEmoji();
			advanceTimer();
		}, 5000 - valToUse)

		setEmojiTimer(createEmojiTimer);
	}

  return (
   <div className="screensaver">
	   {emojis.map((ele, i) => (
	   		<div key={ele.timeOfBirth} className="single" style={{top: mousePosToUse.y, left: mousePosToUse.x}}>
	   			<div className={`inner ${ele.dir}`} style={{transform: `translate(${ele.active ? ele.destX : 0}px, ${ele.active ? ele.destY : 0}px)`, transitionDuration: `${ele.isThisSpecial ? 4 : 20}s`}}>
	   				<span>{letters[ele.letter]}</span>
   				</div>
			</div>
	   	))}
	</div>
  );
}

export default connect((state) => ({
	mousePos: state.App.mousePos
}))(ScreenSaver);