import React from 'react'

import {Link} from 'react-router-dom'

import ScrollVisibilityComponent from './../ScrollVisibilityComponent'
import LazyImageComponent from './../LazyImageComponent'

export const SingleSectionText = (props) => (
    <div className={`PageModule text margin-${props.data.top_margin_size}`}>
        <div className="col col8 col16-m">
            {props.data.media_type_left_column == 'image' ? 
                <div className="inner">
                    {
                        props.data.image && props.data.image_links_to == 'internal' ? <Link to={`/${props.data.internal_page[0].post_type == 'page' ? '' : props.data.internal_page[0].post_type+'/'}${props.data.internal_page[0].post_name}`}><LazyImageComponent height={props.data.image.height} width={props.data.image.width} url={props.data.image.sizes.fullscreen}/></Link> :
                        props.data.image && props.data.image_links_to == 'external' ? <a href={props.data.external_url} target="_blank"><LazyImageComponent height={props.data.image.height} width={props.data.image.width} url={props.data.image.sizes.fullscreen}/></a> :
                        props.data.image ? <LazyImageComponent height={props.data.image.height} width={props.data.image.width} url={props.data.image.sizes.fullscreen}/> :
                        null
                    }
                    {props.data.image_caption ? <div className="caption">{props.data.image_caption}</div> : null}
                </div>
            : props.data.media_type_left_column == 'embed' ? 
                <div className="inner">
                    <div className="embed" dangerouslySetInnerHTML={{__html: props.data.embed_code}}/>
                    {props.data.image_caption ? <div className="caption">{props.data.image_caption}</div> : null}
                </div>
            : props.data.media_type_left_column == 'image_url' ? 
                <div className="inner extimg">
                    {
                        props.data.image_url && props.data.image_links_to == 'internal' ? <Link to={`/${props.data.internal_page[0].post_type == 'page' ? '' : props.data.internal_page[0].post_type+'/'}${props.data.internal_page[0].post_name}`}><img src={props.data.image_url}/></Link> :
                        props.data.image_url && props.data.image_links_to == 'external' ? <a href={props.data.external_url} target="_blank"><img src={props.data.image_url}/></a> :
                        props.data.image_url ? <img src={props.data.image_url}/> :
                        null
                    }
                    {props.data.image_caption ? <div className="caption">{props.data.image_caption}</div> : null}
                </div>
            : null}
        </div>
        <div className="col col8 col16-m single-text" dangerouslySetInnerHTML={{__html: props.data.text}}>
        </div>
    </div>
    )

export const SingleSectionQuote = (props) => (
    <div className="PageModule quote">
        <div className="col col8 col16-m"/>
        <div className="col col8 col16-m">
            <div className="text">{props.data.quote}</div>
            <div className="name">{props.data.name}</div>
            <div className="title">{props.data.title}</div>
        </div>
    </div>
    )